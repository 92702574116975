<template>
  <div>
    <window-header></window-header>
    <nav-form></nav-form>
    <section>
      <article style="height: 126px; width:626px">
        <field
          widget="image"
          name="imagen"
          style="position:absolute;width:52px; height:52px; top: 4px; left:6px;"
        />
        <field
          name="codigo"
          widget="input"
          searchable
          label="Código"
          placeholder="Cod"
          help="Codigo del Metal"
          width="50px"
          :readonly="mode == 'edit'"
          inputStyle="text-align:center; font-weight: bold;"
          style="top:9px; left:68px;"
        />
        <field
          name="metal"
          widget="input"
          searchable
          label="Metal"
          placeholder="Metal"
          help="Metal"
          inputStyle="text-align:left; font-weight: bold;"
          width="200px"
          style="top:9px; left:128px;"
        />
        <field
          name="descripcion"
          widget="input"
          searchable
          label="Descripcion"
          placeholder="Descripción"
          width="260px"
          style="top:9px; left:341px;"
        />
        <field
          name="abreviatura"
          widget="input"
          searchable
          label="Abrev"
          inline-label
          placeholder
          width="26px"
          style="top:65px; left:17px;"
        />
        <field
          name="factor"
          widget="input"
          type="number"
          dec="4"
          searchable
          label="Factor"
          inline-label
          placeholder="Factor"
          width="60px"
          style="top:65px; left:89px;"
        />
        <field
          name="cotizacion"
          widget="input"
          searchable
          label="Cotizacion"
          inline-label
          placeholder="Cotizacion"
          width="60px"
          style="top:65px; left:200px;"
        />
        <field
          name="re"
          widget="toggle"
          searchable
          :labels="{ checked: 'Sí', unchecked: 'No'}"
          offtext="No"
          label="RE"
          :width="45"
          inline-label
          style="top:60px; left:335px;"
        />
        <field
          name="fecha"
          widget="input"
          type="date"
          label="Fecha"
          inline-label
          placeholder="Fecha"
          width="120px"
          style="top:65px; left:410px;"
          :readonly="true"
        />
      </article>
    </section>
    <v-collapse-wrapper ref="main-collapse" :active="true">
      <div class="header" v-collapse-toggle>
        <div class="item-title">Listado</div>
        <div class="item-after">{{count}}</div>
      </div>
      <div class="my-content" v-collapse-content>
        <hot-table ref="hotTableComponent" :settings="htSettings"></hot-table>
      </div>
    </v-collapse-wrapper>
  </div>
</template>
<script>
import WindowMixin from "./../components/WindowMixin.vue";
import rFormMixin from "./../components/rFormMixin.vue";
import rTableMixin from "./../components/rTableMixin.vue";
export default {
  mixins: [WindowMixin, rFormMixin, rTableMixin],
  data: function() {
    return {
      title: "Metales",
      dbAdapter: "metal",
      primary: "codigo",
      sequence: { name: "metal" }
    };
  }
};
</script>
